import { Box } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { useMessager, useLoader, useDocument } from "@/hooks";
import {
  useDeleteDocumentMutation,
  useLazyGetAllDocumentsByOwnerIdQuery,
} from "@/service";

import { colDefs } from "./UserDocumentStatus.map";
import { containerSx, dataGridSx } from "./UserDocumentStatus.styles";
import {
  IUserDocumentStatusDataGrid,
  Document,
} from "./UserDocumentStatus.types";
import CustomToolbar from "./UserDocumentStatusToolbar";
import DataGridEmpty from "../DataGridEmpty";

const UserDocumentStatus: React.FC = () => {
  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [rows, setRows] = useState<IUserDocumentStatusDataGrid[]>([]);

  const { setMessage, clearMessage } = useMessager();
  const { setLoader } = useLoader();
  const [deleteDocument, { isLoading, isSuccess }] =
    useDeleteDocumentMutation();
  const [getAllDocuments, { data, isFetching: isFetchingDocuments }] =
    useLazyGetAllDocumentsByOwnerIdQuery();
  const { allDocumentsCreatedSt, filtersSt } = useDocument();

  const rowCountRef = useRef(data?.pagination?.count || 0);

  const rowCount = useMemo(() => {
    if (data?.pagination?.count !== undefined) {
      rowCountRef.current = data?.pagination?.count;
    }
    return rowCountRef.current;
  }, [data?.pagination?.count]);

  useEffect(() => {
    setLoader(isLoading);
    getAllDocuments({
      page: paginationModel.page + 1,
      per_page: paginationModel.pageSize,
      filters: filtersSt,
    });
  }, [paginationModel, isLoading, isSuccess, filtersSt]);

  useEffect(() => {
    setLoader(isFetchingDocuments);
  }, [isFetchingDocuments]);

  const openDeleteModal = (documentId: number) => {
    setMessage({
      title: "¿Deseas eliminar este documento?",
      body: "Ten en cuenta que habrás perdido todos los archivos relacionados con este documento y no podrás deshacer esta acción.",
      open: true,
      buttonCloseTitle: "Cancelar",
      onClose: clearMessage,
      type: "modal",
      confirmTitle: "Aceptar",
      onConfirm: () => deleteDocument(documentId),
    });
  };

  const handleDocumentsData = (documents: Document[]) => {
    if (documents) {
      const docsAsDataGrid: IUserDocumentStatusDataGrid[] = documents.map(
        (doc: Document) => ({
          actions: () => openDeleteModal(doc.id),
          uuid: doc.uuid,
          id: doc.id,
          created_at: doc.created_at,
          status: doc.status,
          document_code: doc.document_code,
        }),
      );

      setRows(docsAsDataGrid);
    }
  };

  useEffect(() => {
    handleDocumentsData(allDocumentsCreatedSt);
  }, [allDocumentsCreatedSt]);

  return (
    <Box sx={containerSx} data-testid='user-documents-status-component'>
      <DataGrid
        apiRef={apiRef}
        columns={colDefs}
        rows={rows ?? []}
        rowCount={rowCount}
        paginationMode='server'
        pageSizeOptions={[10, 20]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        loading={isFetchingDocuments}
        sx={dataGridSx}
        disableRowSelectionOnClick
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: DataGridEmpty,
        }}
        localeText={{
          filterOperatorContains: "Contiene",
          filterOperatorEquals: "Igual",
          filterOperatorStartsWith: "Comienza con",
          filterOperatorEndsWith: "Termina con",
          filterOperatorIsEmpty: "Está vacío",
          filterOperatorIsNotEmpty: "No esta vacío",
          filterOperatorIsAnyOf: "Es cualquiera de",
          filterPanelOperator: "Criterio",
          filterPanelColumns: "Columnas",
          filterPanelInputLabel: "Valor",
          filterPanelInputPlaceholder: "Ingresa un valor",
          columnMenuSortAsc: "Ordenar ASC",
          columnMenuSortDesc: "Ordenar DESC",
          columnMenuHideColumn: "Ocultar columna",
          columnMenuManageColumns: "Gestionar columnas",
          columnMenuFilter: "Filtrar",
          MuiTablePagination: {
            labelRowsPerPage: "Filas por página",
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              page: data?.pagination?.page - 1,
              pageSize: data?.pagination?.total_page,
            },
          },
        }}
      />
    </Box>
  );
};

export default UserDocumentStatus;
