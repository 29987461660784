import {
  ArrowBack,
  Check,
  ContactMailOutlined,
  CreditScoreOutlined,
  DescriptionOutlined,
  FileDownloadOutlined,
  FolderZipOutlined,
  GroupsOutlined,
  MailOutlined,
  PendingActionsOutlined,
  PersonOutlined,
  PictureAsPdfOutlined,
  PriceCheckOutlined,
  ReceiptOutlined,
  RuleOutlined,
  SmartphoneOutlined,
  TagOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  SnackbarCloseReason,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Permissions } from "@/helpers/permissions";
import { useLoader, useFile, useMessager } from "@/hooks";
import AuthorizedRoute from "@/routes/AuthorizedRoute";
import {
  useLazyDownloadFileByFilenameQuery,
  useLazyGetOneDocumentQuery,
} from "@/service";
import { EDocumentSignerRoles } from "@/state/Document/slice.types";

import {
  alertSx,
  avatarSx,
  backButtonSx,
  containerSx,
  listItemSx,
  secondaryTextSx,
  typographySx,
} from "./DocumentDetails.styles";
import DocumentDetailsSignature from "./DocumentDetailsSignature";
import {
  Document,
  DocumentPaymentStatusMap,
  DocumentStatusMap,
  EDocumentStatus,
  Signer,
} from "../DocumentDetailData/DocumentDetailData.types";
import PDFViewer from "../PDFViewer";

const DocumentDetails: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const [open, setOpen] = useState<boolean>(false);
  const [document, setDocument] = useState<Document | undefined>();
  const [payer, setPayer] = useState<Signer | undefined>();

  const [getDocumentById, { data, isFetching }] = useLazyGetOneDocumentQuery();
  const [downloadFileByFilename] = useLazyDownloadFileByFilenameQuery();

  const { setMessage } = useMessager();
  const { setLoader } = useLoader();
  const { downloadFile } = useFile();

  useEffect(() => {
    getDocumentById(id);
  }, [id]);

  useEffect(() => {
    setLoader(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (data) {
      setDocument(data.data as Document);
    }
  }, [data]);

  useEffect(() => {
    setPayer(
      document?.signatures.find((signer: Signer) => {
        return (
          signer.role === EDocumentSignerRoles.PAYER ||
          signer.role === EDocumentSignerRoles.SIGNER_PAYER
        );
      }),
    );
  }, [document]);

  const downloadZip = async () => {
    setLoader(true);
    const zipName = `${document?.uuid}.zip`;
    const { data, isSuccess } = await downloadFileByFilename(zipName);
    if (isSuccess) {
      setOpen(isSuccess);
      setLoader(false);
      downloadFile(data.data.file, zipName, "application/zip");
    }
  };

  const viewDocumentAsPDF = async () => {
    setLoader(true);
    const pdfName = `${document?.uuid}.pdf`;
    const { data, isSuccess } = await downloadFileByFilename(pdfName);
    if (isSuccess && data.data.file) {
      setLoader(false);
      setMessage({
        title: "Documento cargado",
        buttonCloseTitle: "Cerrar",
        type: "modal",
        open: true,
        body: (
          <PDFViewer base64={`data:application/pdf;base64,${data.data.file}`} />
        ),
      });
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <AuthorizedRoute allowedPermission={Permissions.VIEW_DOCUMENT}>
      {document && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={9}>
            <Box sx={containerSx} data-testid='document-detail-data-component'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack
                    direction='row'
                    alignItems={{ xs: "flex-start", md: "center" }}
                  >
                    <Tooltip title='Regresar'>
                      <IconButton
                        aria-label='go back'
                        sx={backButtonSx}
                        onClick={goBack}
                      >
                        <ArrowBack />
                      </IconButton>
                    </Tooltip>
                    <Typography variant='h5' component='h2'>
                      Documento:{" "}
                      <Typography
                        component='span'
                        variant='h5'
                        sx={typographySx}
                      >
                        {document.uuid}
                      </Typography>
                    </Typography>
                  </Stack>
                  <Divider sx={{ mt: 2 }} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                      <List>
                        <ListItem sx={{ px: 0 }}>
                          <ListItemAvatar>
                            <Avatar variant='rounded' sx={avatarSx}>
                              <DescriptionOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary='Detalles'
                            secondary='Datos del documento'
                            primaryTypographyProps={{ fontWeight: "bold" }}
                          />
                        </ListItem>
                      </List>
                      <List dense>
                        <ListItem sx={listItemSx}>
                          <ListItemAvatar>
                            <Avatar>
                              <TagOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary='ID del documento'
                            secondary={document.document_code}
                            primaryTypographyProps={{
                              fontWeight: "bold",
                              variant: "body2",
                            }}
                          />
                        </ListItem>
                        <ListItem sx={listItemSx}>
                          <ListItemAvatar>
                            <Avatar>
                              <GroupsOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary='Participantes'
                            secondary={document.signatures.length}
                            primaryTypographyProps={{
                              fontWeight: "bold",
                              variant: "body2",
                            }}
                          />
                        </ListItem>
                        <ListItem sx={listItemSx}>
                          <ListItemAvatar>
                            <Avatar>
                              <RuleOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary='Estado del documento'
                            secondary={
                              DocumentStatusMap[document.status as string]
                            }
                            primaryTypographyProps={{
                              fontWeight: "bold",
                              variant: "body2",
                            }}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <List>
                        <ListItem sx={{ px: 0 }}>
                          <ListItemAvatar>
                            <Avatar variant='rounded' sx={avatarSx}>
                              <ContactMailOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary='Propietario del documento'
                            secondary='Datos personales'
                            primaryTypographyProps={{ fontWeight: "bold" }}
                          />
                        </ListItem>
                      </List>
                      <List dense>
                        <ListItem sx={listItemSx}>
                          <ListItemAvatar>
                            <Avatar>
                              <PersonOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary='Nombre'
                            secondary={`${document.owner.first_name} ${document.owner.last_name} ${document.owner.mother_last_name}`}
                            primaryTypographyProps={{
                              fontWeight: "bold",
                              variant: "body2",
                            }}
                          />
                        </ListItem>
                        <ListItem sx={listItemSx}>
                          <ListItemAvatar>
                            <Avatar>
                              <MailOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary='E-mail'
                            secondary={document.owner.email}
                            primaryTypographyProps={{
                              fontWeight: "bold",
                              variant: "body2",
                            }}
                          />
                        </ListItem>
                        <ListItem sx={listItemSx}>
                          <ListItemAvatar>
                            <Avatar>
                              <SmartphoneOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary='Teléfono'
                            secondary={document.owner.phone}
                            primaryTypographyProps={{
                              fontWeight: "bold",
                              variant: "body2",
                            }}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <List>
                        <ListItem sx={{ px: 0 }}>
                          <ListItemAvatar>
                            <Avatar variant='rounded' sx={avatarSx}>
                              <PendingActionsOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary='Acciones del documento'
                            secondary='Ver o Descargar el documento'
                            primaryTypographyProps={{ fontWeight: "bold" }}
                          />
                        </ListItem>
                      </List>
                      <List dense>
                        <ListItem
                          sx={listItemSx}
                          secondaryAction={
                            <Tooltip title='Ver Documento'>
                              <IconButton
                                onClick={viewDocumentAsPDF}
                                color='atmosphere'
                                size={isDesktop ? "medium" : "small"}
                              >
                                <VisibilityOutlined
                                  fontSize={isDesktop ? "medium" : "small"}
                                />
                              </IconButton>
                            </Tooltip>
                          }
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <PictureAsPdfOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary='Documento PDF'
                            secondary={document.file.name}
                            primaryTypographyProps={{
                              fontWeight: "bold",
                              variant: "body2",
                            }}
                            secondaryTypographyProps={{
                              // noWrap: true,
                              sx: secondaryTextSx,
                            }}
                          />
                        </ListItem>
                        {document.status === EDocumentStatus.SIGNED && (
                          <ListItem
                            sx={listItemSx}
                            secondaryAction={
                              <Tooltip title='Descargar ZIP'>
                                <IconButton
                                  onClick={downloadZip}
                                  color='atmosphere'
                                  size={isDesktop ? "medium" : "small"}
                                >
                                  <FileDownloadOutlined
                                    fontSize={isDesktop ? "medium" : "small"}
                                  />
                                </IconButton>
                              </Tooltip>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <FolderZipOutlined />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary='Archivo Comprimido'
                              secondary={`${document.uuid}.zip`}
                              primaryTypographyProps={{
                                fontWeight: "bold",
                                variant: "body2",
                              }}
                              secondaryTypographyProps={{
                                // noWrap: true,
                                sx: secondaryTextSx,
                              }}
                            />
                          </ListItem>
                        )}
                      </List>
                    </Grid>
                    {document.status === EDocumentStatus.CREATED && (
                      <Grid item xs={12} lg={6}>
                        <List>
                          <ListItem sx={{ px: 0 }}>
                            <ListItemAvatar>
                              <Avatar variant='rounded' sx={avatarSx}>
                                <CreditScoreOutlined />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary='Resumen del pedido'
                              secondary='Datos del pago'
                              primaryTypographyProps={{ fontWeight: "bold" }}
                            />
                          </ListItem>
                        </List>
                        <List dense>
                          <ListItem sx={listItemSx}>
                            <ListItemAvatar>
                              <Avatar>
                                <ReceiptOutlined />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary='Estado del pago de las firmas'
                              secondary={
                                DocumentPaymentStatusMap[
                                  document.payment_status
                                ]
                              }
                              primaryTypographyProps={{
                                fontWeight: "bold",
                                variant: "body2",
                              }}
                            />
                          </ListItem>
                          <ListItem sx={listItemSx}>
                            <ListItemAvatar>
                              <Avatar>
                                <PriceCheckOutlined />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary='Monto'
                              secondary={`$${payer?.payment.toFixed(2)} MXN`}
                              primaryTypographyProps={{
                                fontWeight: "bold",
                                variant: "body2",
                              }}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Grid container spacing={2}>
              {document.signatures.map(signature => (
                <Grid item xs={12} key={signature.id}>
                  <DocumentDetailsSignature signature={signature} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          icon={<Check fontSize='inherit' />}
          severity='success'
          sx={alertSx}
        >
          El documento se ha descargado correctamente.
        </Alert>
      </Snackbar>
    </AuthorizedRoute>
  );
};

export default DocumentDetails;
