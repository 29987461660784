import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@/store";

const selfState = (state: RootState) => state.document;
export const documentSelector = createSelector(selfState, state => state);
export const stepSelector = createSelector(
  selfState,
  state => state.documentCreationStep,
);
export const signersSelector = createSelector(
  selfState,
  state => state.documentSigners,
);
export const documentBase64Selector = createSelector(
  selfState,
  state => state.documentBase64,
);
export const allDocumentsCreatedSelector = createSelector(
  selfState,
  state => state.documents,
);
export const errorSignersSelector = createSelector(
  selfState,
  state => state.error,
);
export const statisticsSelector = createSelector(
  selfState,
  state => state.documentsStatistics,
);
export const filtersSelector = createSelector(
  selfState,
  state => state.filters,
);
export const allContractTypes = createSelector(
  selfState,
  state => state.contractTypes,
);
