import { Box } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { FC, useEffect, useMemo, useRef, useState } from "react";

import { Permissions } from "@/helpers/permissions";
import { useDocument, useLoader } from "@/hooks";
import AuthorizedRoute from "@/routes/AuthorizedRoute";
import { useLazyGetAllDocumentsQuery } from "@/service";

import { colDefs } from "./DocumentList.map";
import { boxContainerSx, dataGridSx } from "./DocumentList.styles";
import DocumentListToolbar from "./DocumentListToolbar";
import DataGridEmpty from "../DataGridEmpty";

const DocumentList: FC = () => {
  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const [getAllDocuments, { data, isLoading, isFetching }] =
    useLazyGetAllDocumentsQuery();
  const { allDocumentsCreatedSt } = useDocument();
  const { setLoader } = useLoader();

  useEffect(() => {
    getAllDocuments({
      page: paginationModel.page + 1,
      per_page: paginationModel.pageSize,
    });
  }, [paginationModel]);

  const rowCountRef = useRef(data?.pagination?.count || 0);

  const rowCount = useMemo(() => {
    if (data?.pagination?.count !== undefined) {
      rowCountRef.current = data?.pagination?.count;
    }
    return rowCountRef.current;
  }, [data?.pagination?.count]);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  return (
    <AuthorizedRoute allowedPermission={Permissions.LIST_DOCUMENTS}>
      <Box sx={boxContainerSx} data-testid='documents-list-component'>
        <DataGrid
          apiRef={apiRef}
          columns={colDefs}
          rows={allDocumentsCreatedSt ?? []}
          rowCount={rowCount}
          paginationMode='server'
          pageSizeOptions={[10, 20]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          disableRowSelectionOnClick
          loading={isFetching}
          sx={dataGridSx}
          slots={{
            toolbar: DocumentListToolbar,
            noRowsOverlay: DataGridEmpty,
          }}
          localeText={{
            filterOperatorContains: "Contiene",
            filterOperatorEquals: "Igual",
            filterOperatorStartsWith: "Comienza con",
            filterOperatorEndsWith: "Termina con",
            filterOperatorIsEmpty: "Está vacío",
            filterOperatorIsNotEmpty: "No esta vacío",
            filterOperatorIsAnyOf: "Es cualquiera de",
            filterPanelOperator: "Criterio",
            filterPanelColumns: "Columnas",
            filterPanelInputLabel: "Valor",
            filterPanelInputPlaceholder: "Ingresa un valor",
            columnMenuSortAsc: "Ordenar ASC",
            columnMenuSortDesc: "Ordenar DESC",
            columnMenuHideColumn: "Ocultar columna",
            columnMenuManageColumns: "Gestionar columnas",
            columnMenuFilter: "Filtrar",
            MuiTablePagination: {
              labelRowsPerPage: "Filas por página",
            },
          }}
          initialState={{
            pagination: {
              paginationModel: {
                page: data?.pagination?.page - 1,
                pageSize: data?.pagination?.total_page,
              },
            },
          }}
          disableColumnMenu
        />
      </Box>
    </AuthorizedRoute>
  );
};

export default DocumentList;
