import { lighten, SxProps, Theme } from "@mui/material";

export const containerSx: SxProps<Theme> = theme => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[24],
  padding: {
    xs: theme.spacing(2),
    md: theme.spacing(3),
  },
  borderRadius: "10px",
});

export const secondaryTextSx: SxProps = () => ({
  width: {
    xs: "70%",
    sm: "80%",
    md: "90%",
    xl: "100%",
  },
});

export const listItemSx: SxProps = () => ({
  px: 0,
  "& .MuiListItemSecondaryAction-root": {
    right: {
      xs: 0,
      md: 16,
    },
  },
});

export const avatarSx: SxProps<Theme> = theme => ({
  backgroundColor: theme.palette.primary.light,
});

export const avatarLightSx: SxProps<Theme> = theme => ({
  backgroundColor: lighten(theme.palette.primary?.light as string, 0.75),
});

export const avatarIconSx: SxProps<Theme> = theme => ({
  fill: theme.palette.primary.light,
});

export const containerItemSx: SxProps<Theme> = theme => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  borderRadius: "10px",
  boxShadow: theme.shadows[24],
});

export const typographySx: SxProps<Theme> = theme => ({
  color: lighten(theme.palette.common.black, 0.4),
});

export const alertSx: SxProps<Theme> = theme => ({
  alignItems: "center",
  "&.MuiAlert-standard.MuiAlert-standardSuccess": {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.success?.main,
  },
});

export const completedChipSx: SxProps<Theme> = theme => ({
  backgroundColor: lighten(theme.palette.grass?.light as string, 0.6),
});

export const pendingChipSx: SxProps<Theme> = theme => ({
  backgroundColor: lighten(theme.palette.autumn?.light as string, 0.6),
});

export const backButtonSx: SxProps<Theme> = theme => ({
  color: theme.palette.primary.main,
  border: `solid 1px ${theme.palette.primary.light}`,
  mr: 3,
});
