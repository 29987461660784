import { PersonOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import AccountCreditCardOutlineIcon from "mdi-react/AccountCreditCardOutlineIcon";
import AccountEditOutlineIcon from "mdi-react/AccountEditOutlineIcon";
import AccountTagOutlineIcon from "mdi-react/AccountTagOutlineIcon";
import { FC } from "react";

import { EDocumentSignerRoles } from "@/state/Document/slice.types";

import {
  avatarSx,
  completedChipSx,
  containerItemSx,
  pendingChipSx,
  typographySx,
} from "./DocumentDetails.styles";
import {
  EBiometricValidationMap,
  Signer,
} from "../DocumentDetailData/DocumentDetailData.types";

interface DocumentDetailsSignatureProps {
  signature: Signer;
}

const DocumentDetailsSignature: FC<DocumentDetailsSignatureProps> = ({
  signature,
}) => {
  const participant = () => {
    switch (signature.role) {
      case EDocumentSignerRoles.PAYER:
        return "Pagador";
      case EDocumentSignerRoles.SIGNER:
        return "Firmante";
      case EDocumentSignerRoles.SIGNER_PAYER:
        return "Firmante y Pagador";
    }
  };

  const participantIcon = () => {
    switch (signature.role) {
      case EDocumentSignerRoles.PAYER:
        return <AccountCreditCardOutlineIcon />;
      case EDocumentSignerRoles.SIGNER:
        return <AccountEditOutlineIcon />;
      case EDocumentSignerRoles.SIGNER_PAYER:
        return <AccountTagOutlineIcon />;
    }
  };

  return (
    <Box sx={containerItemSx} data-testid='document-signature-item-component'>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Chip
            label={participant()}
            icon={participantIcon()}
            color='default'
            size='small'
          />
        </Grid>
        <Grid item xs={12}>
          <List dense>
            <ListItem sx={{ px: 0 }}>
              <ListItemAvatar>
                <Avatar sx={avatarSx}>
                  <PersonOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={signature.email}
                primaryTypographyProps={{
                  fontWeight: "bold",
                  variant: "body2",
                  noWrap: true,
                }}
                secondary={`${signature.first_name} ${signature.last_name} ${signature.mother_last_name}`}
              />
            </ListItem>
          </List>
          <Typography component='p' variant='body2' sx={typographySx}>
            <Typography
              component='span'
              fontWeight='bold'
              variant='body2'
              color='black'
            >
              RFC:{" "}
            </Typography>
            {signature.rfc}
          </Typography>
          <Typography component='p' variant='body2' sx={typographySx}>
            <Typography
              component='span'
              fontWeight='bold'
              variant='body2'
              color='black'
            >
              Código:{" "}
            </Typography>
            {signature.signature_code}
          </Typography>
          <Typography component='div' variant='body2' sx={typographySx}>
            <Typography
              component='span'
              fontWeight='bold'
              variant='body2'
              color='black'
            >
              Estado de la Firma:{" "}
            </Typography>
            <Chip
              label={signature.is_signed ? "Firmado" : "Por firmar"}
              size='small'
              sx={signature.is_signed ? completedChipSx : pendingChipSx}
            />
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography component='div' variant='body2' sx={typographySx}>
            <Typography
              component='span'
              fontWeight='bold'
              variant='body2'
              color='black'
            >
              Validación Biométrica:{" "}
            </Typography>
            <Chip
              label={
                signature.biometric_validation?.is_done
                  ? "Completada"
                  : "Por completar"
              }
              size='small'
              sx={
                signature.biometric_validation?.is_done
                  ? completedChipSx
                  : pendingChipSx
              }
            />
          </Typography>
          {signature.biometric_validation?.current_step && (
            <Typography component='p' variant='body2' sx={typographySx}>
              <Typography
                component='span'
                fontWeight='bold'
                variant='body2'
                color='black'
              >
                Paso actual de la Validación:{" "}
              </Typography>
              {
                EBiometricValidationMap[
                  signature.biometric_validation?.current_step as string
                ]
              }
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentDetailsSignature;
