const useFile = () => {
  const downloadFile = (
    base64: string,
    fileName: string,
    type = "application/pdf",
  ) => {
    const bytes = Uint8Array.from(atob(base64), c => c.charCodeAt(0));
    const blob = new Blob([bytes], { type });
    const blobUrl = URL.createObjectURL(blob);
    const link = window.document.createElement("a");
    link.href = blobUrl;
    link.download = fileName;
    window.document.body.appendChild(link);
    link.click();

    window.document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  };

  return {
    downloadFile,
  };
};

export default useFile;
