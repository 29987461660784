import { createBrowserRouter } from "react-router-dom";

import DocumentCreation from "@/components/DocumentCreation";
import DocumentCreationForm from "@/components/DocumentCreationForm";
import DocumentDetails from "@/components/DocumentDetails";
import DocumentList from "@/components/DocumentList";
import DocumentReadyNotification from "@/components/DocumentReadyNotification";
import DocumentsStatusDataGrid from "@/components/DocumentsStatusDataGrid";
import Layout from "@/components/Layout";
import LoginForm from "@/components/LoginForm";
import PaymentList from "@/components/PaymentList";
import {
  PermissionForm,
  UpdatePermissionForm,
} from "@/components/PermissionForm";
import PermissionList from "@/components/PermissionList";
import PrivateRoute from "@/components/PrivateRoute";
import SignatureInventoryList from "@/components/SignatureInventoryList";
import SignaturePurchaseForm from "@/components/SignaturePurchaseForm";
import SignaturePackageList from "@/components/SignaturePurchaseList";
import Signers from "@/components/Signers";
import SignUpForm from "@/components/SignUpForm";
import UpdateUser from "@/components/UpdateUser";
import UploadDocument from "@/components/UploadDocument";
import UserDetails from "@/components/UserDetails";
import UserDocumentStatus from "@/components/UserDocumentStatus";
import { UserForm, UpdateUserForm } from "@/components/UserForm";
import UsersList from "@/components/UsersList";
import { Admin } from "@/pages/Admin";
import DocumentDetail from "@/pages/DocumentDetail";
import ResetPassword from "@/pages/ResetPassword";
import UpdatePassword from "@/pages/ResetPassword/UpdatePassword";
import Root from "@/pages/Root";
import Sessions from "@/pages/Sessions";
import SignatureCredits from "@/pages/SignatureCredits";
import User from "@/pages/User";
import { store } from "@/store";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    element: <Root />,
    loader: () => {
      return store.getState().session;
    },
    children: [
      {
        path: "session",
        element: <Sessions />,
        children: [
          { path: "login", element: <LoginForm /> },
          { path: "signup", element: <SignUpForm /> },
          { path: "reset-password", element: <ResetPassword /> },
          { path: "update-password", element: <UpdatePassword /> },
        ],
      },
      {
        path: "dashboard",
        element: <PrivateRoute />,
        children: [
          {
            path: "v1",
            element: <Layout />,
            children: [
              {
                path: "user-settings",
                element: <User />,
                children: [
                  {
                    path: "update",
                    element: <UpdateUser />,
                  },
                ],
              },
              {
                path: "create-document",
                element: <DocumentCreation />,
                children: [
                  {
                    path: "type",
                    element: <DocumentCreationForm />,
                  },
                  {
                    path: "signers",
                    element: <Signers />,
                  },
                ],
              },
              {
                path: "upload-document",
                element: <UploadDocument />,
                children: [
                  {
                    path: "signers",
                    element: <Signers createPDFDocument />,
                  },
                  {
                    path: "document-is-ready",
                    element: <DocumentReadyNotification />,
                  },
                ],
              },
              {
                path: "review-documents",
                element: <DocumentsStatusDataGrid />,
                children: [
                  {
                    path: "all",
                    element: <UserDocumentStatus />,
                  },
                ],
              },
              {
                path: "document-detail/:id",
                element: <DocumentDetail />,
              },
              {
                path: "signature-credits",
                element: <SignatureCredits />,
                children: [
                  {
                    path: "purchase-credits",
                    element: <SignaturePurchaseForm />,
                  },
                  {
                    path: "list-purchases",
                    element: <SignaturePackageList />,
                  },
                ],
              },
              {
                path: "admin",
                element: <Admin />,
                children: [
                  {
                    path: "",
                    element: <></>,
                  },
                  {
                    path: "manage-users",
                    children: [
                      {
                        path: "create",
                        element: <UserForm />,
                      },
                      {
                        path: "list",
                        element: <UsersList />,
                      },
                      {
                        path: "details/:id",
                        element: <UserDetails />,
                      },
                      {
                        path: "update/:id",
                        element: <UpdateUserForm />,
                      },
                    ],
                  },
                  {
                    path: "manage-signature-inventory",
                    children: [
                      {
                        path: "list",
                        element: <SignatureInventoryList />,
                      },
                    ],
                  },
                  {
                    path: "manage-payments",
                    children: [
                      {
                        path: "list",
                        element: <PaymentList />,
                      },
                    ],
                  },
                  {
                    path: "manage-documents",
                    children: [
                      {
                        path: "list",
                        element: <DocumentList />,
                      },
                      {
                        path: "details/:id",
                        element: <DocumentDetails />,
                      },
                    ],
                  },
                  {
                    path: "manage-permissions",
                    children: [
                      {
                        path: "list",
                        element: <PermissionList />,
                      },
                      {
                        path: "create",
                        element: <PermissionForm />,
                      },
                      {
                        path: "update/:id",
                        element: <UpdatePermissionForm />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    errorElement: <div>404</div>,
  },
  {
    path: "/about",
    element: <div>ABOUT</div>,
  },
]);

export default router;
