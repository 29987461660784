import {
  Check,
  CreditCardOutlined,
  EmailOutlined,
  PersonOutlined,
  Schedule,
  Sync,
  VideocamOutlined,
  DriveFileRenameOutline,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Alert,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import AccountCreditCardOutlineIcon from "mdi-react/AccountCreditCardOutlineIcon";
import AccountEditOutlineIcon from "mdi-react/AccountEditOutlineIcon";
import AccountTagOutlineIcon from "mdi-react/AccountTagOutlineIcon";
import React, { useEffect } from "react";

import { Permissions } from "@/helpers/permissions";
import { useLoader, useMessager, useSession } from "@/hooks";
import {
  useForwardEmailMutation,
  useLazyDownloadVideoQuery,
  useLazyGetOneDocumentQuery,
} from "@/service";
import { EDocumentSignerRoles } from "@/state/Document/slice.types";

import { containerSx, alertSx } from "./DocumentSignerItem.styles";
import { IDocumentSignerItemProps } from "./DocumentSignerItem.types";
import { EBiometricValidation } from "../DocumentDetailData/DocumentDetailData.types";

const DocumentSignerItem: React.FC<IDocumentSignerItemProps> = ({
  signer,
  documentId,
  documentUuid,
  signerId,
  setFile: setFiles,
  setDocument: seContract,
  setSigners,
  paymentStatus,
  verified,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const [
    forwardEmail,
    { isSuccess: successForwardEmail, isLoading: loadingForwardEmail },
  ] = useForwardEmailMutation();
  const [downloadVideo, { data, isLoading: loadingVideo }] =
    useLazyDownloadVideoQuery();
  const [getDocument, { isLoading: loadingDocument }] =
    useLazyGetOneDocumentQuery();
  const { setLoader } = useLoader();
  const { setMessage } = useMessager();
  const { canUse } = useSession();

  useEffect(() => {
    if (!loadingVideo && data) {
      const flashLink = document.createElement("a");
      flashLink.href = `data:video/mp4;base64,${data[0].video}`;
      flashLink.download = `agreement_${documentUuid}_${signer.signature_code}.mp4`;
      document.body.appendChild(flashLink);
      flashLink.click();
      document.body.removeChild(flashLink);
    }
  }, [data, loadingVideo]);

  useEffect(() => {
    if (successForwardEmail) {
      setMessage({
        title: "Correo de veriricación enviado",
        body: "Se ha reenviado el correo de verificación junto con la solicitud de firma para este contrato. Por favor, revise su correo electrónico o informe a los involucrados.",
        buttonCloseTitle: "Entendido",
        open: true,
        type: "modal",
      });
    }
  }, [successForwardEmail]);

  useEffect(() => {
    setLoader(loadingDocument || loadingForwardEmail);
  }, [loadingDocument, loadingForwardEmail]);

  const participant = () => {
    switch (signer.role) {
      case EDocumentSignerRoles.PAYER:
        return "Pagador";
      case EDocumentSignerRoles.SIGNER:
        return "Firmante";
      case EDocumentSignerRoles.SIGNER_PAYER:
        return "Firmante y Pagador";
    }
  };

  const participantIcon = () => {
    switch (signer.role) {
      case EDocumentSignerRoles.PAYER:
        return <AccountCreditCardOutlineIcon />;
      case EDocumentSignerRoles.SIGNER:
        return <AccountEditOutlineIcon />;
      case EDocumentSignerRoles.SIGNER_PAYER:
        return <AccountTagOutlineIcon />;
    }
  };

  const participanTypeStatus = () => {
    switch (signer.role) {
      case EDocumentSignerRoles.PAYER:
        return (
          <Alert
            iconMapping={{
              success: <Check fontSize='inherit' />,
              info: <Schedule fontSize='inherit' />,
            }}
            severity={paymentStatus === "PENDING" ? "success" : "info"}
            sx={alertSx}
          >
            <Typography variant='body2' fontWeight='bold'>
              {paymentStatus === "PENDING"
                ? "Monto a pagar: "
                : "Monto pagado: "}
              {`$${signer.payment} MXN`}
            </Typography>
          </Alert>
        );
      case EDocumentSignerRoles.SIGNER:
        return (
          <Alert
            iconMapping={{
              success: <Check fontSize='inherit' />,
              info: <Schedule fontSize='inherit' />,
            }}
            severity={verified ? "success" : "info"}
            sx={alertSx}
          >
            <Typography variant='body2' color='inherit'>
              Validación biométrica
            </Typography>
          </Alert>
        );
      case EDocumentSignerRoles.SIGNER_PAYER:
        return (
          <Alert
            iconMapping={{
              success: <Check fontSize='inherit' />,
              info: <Schedule fontSize='inherit' />,
            }}
            severity={verified ? "success" : "info"}
            sx={alertSx}
          >
            <Typography component='p' variant='body2' color='inherit'>
              Validación biométrica
            </Typography>
            <Typography variant='body2' fontWeight='bold'>
              {paymentStatus === "PENDING"
                ? "Monto a pagar: "
                : "Monto pagado: "}
              {`$${signer.payment} MXN`}
            </Typography>
          </Alert>
        );
    }
  };

  const update = async (id: number) => {
    const res = await getDocument(id);
    if (res.isSuccess) {
      seContract && seContract(res.data);
      setSigners && setSigners(res.data.signatures);
      setFiles && setFiles(res.data.file);
    }
  };

  const sendPaymentRequest = () => {
    signer.payment_data?.payment_link &&
      window.open(signer.payment_data?.payment_link, "_blank");
  };

  const finishSignatureProcess = () => {
    const baseUrl = process.env.REACT_APP_VALIDATION_APP_BASE_URL;
    if (
      signer.biometric_validation &&
      signer.biometric_validation.current_step ===
        EBiometricValidation.SIGNATURE
    ) {
      window.open(
        `${baseUrl}/?signature_code=${signer.signature_code}&document_uuid=${documentUuid}&document_id=${documentId}&signer_id=${signer.id}&step=${signer.biometric_validation.current_step}`,
        "_blank",
      );
    }
  };

  return (
    <Box sx={containerSx} data-testid='document-signer-item-component'>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Chip
              label={participant()}
              icon={participantIcon()}
              color='default'
              size='small'
            />
            <Tooltip title='Sincronizar'>
              <IconButton
                color='atmosphere'
                onClick={() => update(documentId)}
                size={isDesktop ? "medium" : "small"}
              >
                <Sync fontSize={isDesktop ? "medium" : "small"} />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <List dense>
            <ListItem sx={{ px: 0 }}>
              <ListItemAvatar>
                <Avatar>
                  <PersonOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={signer.email}
                primaryTypographyProps={{
                  fontWeight: "bold",
                  variant: "body2",
                  noWrap: true,
                }}
                secondary={`${signer.first_name} ${signer.last_name} ${signer.mother_last_name}`}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            {participanTypeStatus()}
            {(signer.role === EDocumentSignerRoles.SIGNER ||
              signer.role === EDocumentSignerRoles.SIGNER_PAYER) && (
              <Box>
                {!verified &&
                  paymentStatus === "PAIDOUT" &&
                  canUse(Permissions.PAY_USER_DOCUMENT_SIGNATURES) && (
                    <Tooltip title='Reenviar correo de verificación'>
                      <IconButton
                        color='atmosphere'
                        onClick={() => forwardEmail({ documentId, signerId })}
                        size={isDesktop ? "medium" : "small"}
                      >
                        <EmailOutlined
                          fontSize={isDesktop ? "medium" : "small"}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                {verified &&
                  canUse(Permissions.DOWNLOAD_USER_BIOMETRY_FILES) && (
                    <Tooltip title='Descargar video de verificación'>
                      <IconButton
                        color='atmosphere'
                        onClick={() => downloadVideo({ documentId, signerId })}
                        size={isDesktop ? "medium" : "small"}
                      >
                        <VideocamOutlined
                          fontSize={isDesktop ? "medium" : "small"}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
              </Box>
            )}
          </Stack>
          {(verified &&
            signer.biometric_validation?.current_step ===
              EBiometricValidation.SIGNATURE) && (
              <Button
                sx={{
                  mt: 2,
                }}
                variant='contained'
                fullWidth
                startIcon={<DriveFileRenameOutline />}
                onClick={finishSignatureProcess}
              >
                Finalizar Proceso de Firma
              </Button>
            )}
        </Grid>
        {(signer.role === EDocumentSignerRoles.PAYER ||
          signer.role === EDocumentSignerRoles.SIGNER_PAYER) &&
          paymentStatus === "PENDING" &&
          signer.portion !== 0 && (
            <Grid item xs={12}>
              <Stack direction='row' justifyContent='center' spacing={2}>
                <Button
                  variant='contained'
                  startIcon={<CreditCardOutlined />}
                  disabled={!signer.payment_data?.payment_link?.length}
                  onClick={sendPaymentRequest}
                >
                  Pagar
                </Button>
              </Stack>
            </Grid>
          )}
      </Grid>
    </Box>
  );
};

export default DocumentSignerItem;
