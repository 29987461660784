import { Box } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { FC, useEffect, useMemo, useRef, useState } from "react";

import { useLoader, usePayment } from "@/hooks";
import { useLazyGetAllPaymentsQuery } from "@/service";

import { colDefs } from "./PaymentList.map";
import { containerSx, dataGridSx } from "./PaymentList.styles";
import PaymentListToolbar from "./PaymentListToolbar";
import DataGridEmpty from "../DataGridEmpty";

const PaymentList: FC = () => {
  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const [getAllPayments, { data, isLoading, isFetching }] =
    useLazyGetAllPaymentsQuery();
  const { paymentsSt } = usePayment();
  const { setLoader } = useLoader();

  useEffect(() => {
    getAllPayments({
      page: paginationModel.page + 1,
      per_page: paginationModel.pageSize,
    });
  }, [paginationModel]);

  const rowCountRef = useRef(data?.pagination?.count || 0);

  const rowCount = useMemo(() => {
    if (data?.pagination?.count !== undefined) {
      rowCountRef.current = data?.pagination?.count;
    }
    return rowCountRef.current;
  }, [data?.pagination?.count]);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  return (
    <Box sx={containerSx} data-testid='payment-list-component'>
      <DataGrid
        apiRef={apiRef}
        columns={colDefs}
        rows={paymentsSt ?? []}
        rowCount={rowCount}
        paginationMode='server'
        pageSizeOptions={[10, 20]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick
        loading={isFetching}
        sx={dataGridSx}
        slots={{
          toolbar: PaymentListToolbar,
          noRowsOverlay: DataGridEmpty,
        }}
        localeText={{
          filterOperatorContains: "Contiene",
          filterOperatorEquals: "Igual",
          filterOperatorStartsWith: "Comienza con",
          filterOperatorEndsWith: "Termina con",
          filterOperatorIsEmpty: "Está vacío",
          filterOperatorIsNotEmpty: "No esta vacío",
          filterOperatorIsAnyOf: "Es cualquiera de",
          filterPanelOperator: "Criterio",
          filterPanelColumns: "Columnas",
          filterPanelInputLabel: "Valor",
          filterPanelInputPlaceholder: "Ingresa un valor",
          columnMenuSortAsc: "Ordenar ASC",
          columnMenuSortDesc: "Ordenar DESC",
          columnMenuHideColumn: "Ocultar columna",
          columnMenuManageColumns: "Gestionar columnas",
          columnMenuFilter: "Filtrar",
          MuiTablePagination: {
            labelRowsPerPage: "Filas por página",
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              page: data?.pagination?.page - 1,
              pageSize: data?.pagination?.total_page,
            },
          },
        }}
      />
    </Box>
  );
};

export default PaymentList;
