import { Box } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { FC, useEffect, useMemo, useRef, useState } from "react";

import { Permissions } from "@/helpers/permissions";
import { useUser, useLoader } from "@/hooks";
import AuthorizedRoute from "@/routes/AuthorizedRoute";
import { useLazyGetAllUsersQuery } from "@/service";

import { colDefs } from "./UsersList.map";
import { boxContainerSx, dataGridSx } from "./UsersList.styles";
import UsersListToolbar from "./UsersListToolbar";
import DataGridEmpty from "../DataGridEmpty";

const UsersList: FC = () => {
  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const [getAllUsers, { data, isLoading, isFetching }] =
    useLazyGetAllUsersQuery();
  const { usersSt } = useUser();
  const { setLoader } = useLoader();

  useEffect(() => {
    getAllUsers({
      page: paginationModel.page + 1,
      per_page: paginationModel.pageSize,
    });
  }, [paginationModel]);

  const rowCountRef = useRef(data?.pagination?.count || 0);

  const rowCount = useMemo(() => {
    if (data?.pagination?.count !== undefined) {
      rowCountRef.current = data?.pagination?.count;
    }
    return rowCountRef.current;
  }, [data?.pagination?.count]);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  return (
    <AuthorizedRoute allowedPermission={Permissions.LIST_USERS}>
      <Box sx={boxContainerSx} data-testid='users-list-component'>
        <DataGrid
          apiRef={apiRef}
          columns={colDefs}
          rows={usersSt ?? []}
          rowCount={rowCount}
          paginationMode='server'
          pageSizeOptions={[10, 20]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          disableRowSelectionOnClick
          loading={isFetching}
          sx={dataGridSx}
          slots={{
            toolbar: UsersListToolbar,
            noRowsOverlay: DataGridEmpty,
          }}
          localeText={{
            filterOperatorContains: "Contiene",
            filterOperatorEquals: "Igual",
            filterOperatorStartsWith: "Comienza con",
            filterOperatorEndsWith: "Termina con",
            filterOperatorIsEmpty: "Está vacío",
            filterOperatorIsNotEmpty: "No esta vacío",
            filterOperatorIsAnyOf: "Es cualquiera de",
            filterPanelOperator: "Criterio",
            filterPanelColumns: "Columnas",
            filterPanelInputLabel: "Valor",
            filterPanelInputPlaceholder: "Ingresa un valor",
            columnMenuSortAsc: "Ordenar ASC",
            columnMenuSortDesc: "Ordenar DESC",
            columnMenuHideColumn: "Ocultar columna",
            columnMenuManageColumns: "Gestionar columnas",
            columnMenuFilter: "Filtrar",
            MuiTablePagination: {
              labelRowsPerPage: "Filas por página",
            },
          }}
          initialState={{
            pagination: {
              paginationModel: {
                page: data?.pagination?.page - 1,
                pageSize: data?.pagination?.total_page,
              },
            },
          }}
        />
      </Box>
    </AuthorizedRoute>
  );
};

export default UsersList;
