import { DeleteOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Chip, IconButton, Typography, lighten } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import { Link } from "react-router-dom";

import { theme } from "@/theme";

import { EDocumentStatus } from "./UserDocumentStatus.types";

const chipColor = (status: keyof typeof EDocumentStatus) => {
  switch (status) {
    case "CREATED":
      return lighten(theme.palette.atmosphere?.light ?? "", 0.5);
    case "SIGNED":
      return lighten(theme.palette.success?.light ?? "", 0.5);
    case "SIGNED_PENDING":
      return lighten(theme.palette.autumn?.light ?? "", 0.5);
    default:
      return theme.palette.grey[400];
  }
};

export const colDefs: GridColDef[] = [
  {
    field: "actions",
    headerName: "Acciones",
    width: 150,
    renderCell: ({ row, value }) => (
      <>
        <Link to={`/dashboard/v1/document-detail/${row.uuid}`}>
          <IconButton size='small'>
            <VisibilityOutlined
              width={15}
              sx={{ fill: theme.palette.atmosphere?.main }}
            />
          </IconButton>
        </Link>
        {["CREATED"].includes(row.status) && (
          <IconButton size='small' onClick={value}>
            <DeleteOutlined width={15} color='secondary' />
          </IconButton>
        )}
      </>
    ),
  },
  {
    field: "created_at",
    headerName: "Fecha de creación",
    width: 200,
    renderCell: ({ value }) => {
      return (
        <Typography variant='body1'>{format(value, "dd/MM/yyyy")}</Typography>
      );
    },
  },
  {
    field: "document_code",
    headerName: "ID",
    width: 200,
    renderCell: ({ value }) => <Typography variant='body1'>{value}</Typography>,
  },
  {
    field: "status",
    headerName: "Estatus del documento",
    width: 200,
    renderCell: ({ value }) => (
      <Chip
        label={EDocumentStatus[value as keyof typeof EDocumentStatus]}
        sx={{ background: chipColor(value) }}
      />
    ),
  },
];
