import { SxProps, Theme, styled, Chip, emphasize } from "@mui/material";

export const boxContainerSx: SxProps<Theme> = theme => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: "10px",
  boxShadow: theme.shadows[24],
  display: "grid",
  minHeight: {
    xs: 400,
    md: 330,
  },
});

export const redIconSx: SxProps<Theme> = theme => ({
  color: theme.palette.error.main,
});

export const blueIconSx: SxProps<Theme> = theme => ({
  color: theme.palette.primary.light,
});

export const titleSx: SxProps<Theme> = theme => ({
  color: theme.palette.primary.main,
  mb: 2,
});

export const buttonSx: SxProps<Theme> = theme => ({
  border: `solid 1px ${theme.palette.primary.light}`,
  fontWeight: "bold",
  textTransform: "none",
});

export const toolbarNavSx: SxProps = () => ({
  display: "flex",
  height: "100%",
  flexDirection: {
    xs: "column",
    md: "row",
  },
  alignItem: {
    xs: "flex-start",
    md: "center",
  },
});

export const dataGridSx: SxProps<Theme> = theme => ({
  borderWidth: 0,
  p: theme.spacing(2),
});

export const StyledBreadcrumb = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: theme.spacing(3),
  boxShadow: theme.shadows[1],
  fontWeight: theme.typography.fontWeightRegular,
  "&:hover, &:focus": {
    backgroundColor: emphasize(theme.palette.grey[100], 0.06),
  },
  "&:active": {
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.common.white,
  },
})) as typeof Chip;
