export interface IUserDocumentStatusDataGrid {
  actions: string | ((id?: number) => void);
  uuid: string;
  id: number;
  status: string;
  created_at: string;
  document_code: string;
}

export interface IUserDocumentDataResponse {
  code: number;
  status: string;
  data: Document[];
}

export interface Document {
  id: number;
  uuid: string;
  document_code: string;
  status: string;
  payment_status: string;
  owner_type: string;
  signatures: Signature[];
  owner: Owner;
  is_deleted: boolean;
  created_at: string;
  deleted_at: string | null;
}

export interface Owner {
  id: number;
  uuid: string;
  rfc: string;
  first_name: string;
  last_name: string;
  mother_last_name: string;
  username: string;
  email: string;
  phone: string;
  role: number;
  created_at: string;
}

export interface Signature {
  id: number;
  uuid: string;
  document_id: number;
  signature_code: string;
  rfc: string;
  first_name: string;
  last_name: string;
  mother_last_name: string;
  role: string;
  portion: number;
  payment: number;
  is_paid: boolean;
  is_prepaid: boolean;
  signature_page: number;
  posX: number;
  posY: number;
  created_at: string;
}

export enum EDocumentPaymentStatus {
  PENDING = "Pendiente",
  PAIDOUT = "Pagado",
}

export enum EDocumentStatus {
  CREATED = "Documento creado",
  SIGNED = "Documento firmado",
  REVIEW = "Documento en revisión",
  SIGNED_PENDING = "Documento en revisión",
  DELETED = "Documento eliminado",
}

export enum EDocumentStatusColor {
  CREATED = "ligth",
  SIGNED = "Documento firmado",
  SIGNED_PENDING = "Documento en revisión",
}
