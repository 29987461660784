import { Typography, Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";

import { useDocument, useLoader } from "@/hooks";
import {
  useLazyGetAllDocumentsByOwnerIdQuery,
  useLazyGetDocumentsStatisticsByOwnerIdQuery,
} from "@/service";
import { theme } from "@/theme";

import { StyledPaperContainer } from "./DocumentsOverview.styles";
import { IReviewProps } from "./DocumentsOverview.types";

const DocumentsOverview: FC = () => {
  const [overview, setOverview] = useState<IReviewProps[] | null>(null);
  const { setLoader } = useLoader();
  const { documentsStatisticsSt, setFilters } = useDocument();

  const [getAllDocuments, { isFetching }] =
    useLazyGetAllDocumentsByOwnerIdQuery();
  const [getStatistics] = useLazyGetDocumentsStatisticsByOwnerIdQuery();

  useEffect(() => {
    setLoader(isFetching);
  }, [isFetching]);

  useEffect(() => {
    getStatistics();
  }, []);

  const handleFiltersByStatus = (status: string) => {
    let filters = status;
    if (status !== "") {
      filters = JSON.stringify({
        status,
      });
    }
    setFilters(filters);
    getAllDocuments({
      page: 1,
      per_page: 10,
      filters,
    });
  };

  useEffect(() => {
    if (documentsStatisticsSt) {
      const final: IReviewProps[] = [
        {
          count: documentsStatisticsSt.total ?? 0,
          details: "Documentos en total",
          color: theme.palette.secondary.main,
          onClick: () => handleFiltersByStatus(""),
        },
        {
          count: documentsStatisticsSt.created ?? 0,
          details: "Documentos por pagar",
          color: theme.palette.atmosphere?.main ?? "",
          onClick: () => handleFiltersByStatus("CREATED"),
        },
        {
          count: documentsStatisticsSt.pending ?? 0,
          details: "Documentos por firmar",
          color: theme.palette.autumn?.light ?? "",
          onClick: () => handleFiltersByStatus("SIGNED_PENDING"),
        },
        {
          count: documentsStatisticsSt.signed ?? 0,
          details: "Documentos entregados",
          color: theme.palette.grass?.main ?? "",
          onClick: () => handleFiltersByStatus("SIGNED"),
        },
      ];
      setOverview(final);
    }
  }, [documentsStatisticsSt]);

  return (
    <Grid container spacing={2} data-testid='documents-overview-component'>
      {overview?.map((overview: IReviewProps, index: number) => (
        <Grid item xs={12} md={6} lg={3} key={`${index}_${overview.details}`}>
          <StyledPaperContainer
            variant='elevation'
            elevation={0}
            onClick={() => overview.onClick && overview.onClick()}
            color={overview.color}
          >
            <Typography variant='h4' color='white'>
              {overview.count}
            </Typography>
            <Typography variant='subtitle1' color='white' fontWeight='bold'>
              {overview.details}
            </Typography>
          </StyledPaperContainer>
        </Grid>
      ))}
    </Grid>
  );
};

export default DocumentsOverview;
