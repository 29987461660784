import {
  CloudDownloadOutlined,
  Refresh,
  Tune,
  DescriptionOutlined,
} from "@mui/icons-material";
import {
  Button,
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  Theme,
  IconButton,
  Tooltip,
} from "@mui/material";
import { GridToolbarContainer, useGridApiContext } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useLoader } from "@/hooks";
import { useLazyGetAllDocumentsByOwnerIdQuery } from "@/service";

const UserDocumentStatusToolbar: React.FC = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const apiRef = useGridApiContext();
  const navigation = useNavigate();
  const [getAllDocuments, { isFetching }] =
    useLazyGetAllDocumentsByOwnerIdQuery();
  const { setLoader } = useLoader();
  const handleDownload = () =>
    apiRef.current.exportDataAsCsv({
      fields: ["id", "status", "date", "document_code"],
      fileName: "document",
    });
  const handleFilter = () => apiRef.current.showFilterPanel();
  const handleCreateDocument = () =>
    navigation("/dashboard/v1/create-document");

  useEffect(() => {
    setLoader(isFetching);
  }, [isFetching]);

  return (
    <GridToolbarContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant='body1' fontWeight='bold'>
            Autorización de firmas pendientes de revisión
          </Typography>
          <Typography variant='body2'>
            Aquí podrás visualizar todos los documentos que tienes creados
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction='row' justifyContent='flex-end' spacing={2}>
            {isDesktop ? (
              <>
                <Button
                  variant='text'
                  size='small'
                  onClick={handleFilter}
                  color='atmosphere'
                  startIcon={<Tune />}
                >
                  Filtrar
                </Button>
                <Button
                  variant='text'
                  size='small'
                  onClick={handleDownload}
                  color='atmosphere'
                  startIcon={<CloudDownloadOutlined />}
                >
                  Descargar
                </Button>
                <Button
                  variant='text'
                  size='small'
                  color='atmosphere'
                  onClick={() => getAllDocuments()}
                  startIcon={<Refresh />}
                >
                  Refrescar
                </Button>
                <Button
                  variant='contained'
                  size='small'
                  onClick={handleCreateDocument}
                  startIcon={<DescriptionOutlined />}
                >
                  Iniciar proceso
                </Button>
              </>
            ) : (
              <>
                {/* <Tooltip title="Filtrar">
                  <IconButton size="small" onClick={handleFilter} color="atmosphere">
                    <Tune fontSize="small" />
                  </IconButton>
                </Tooltip> */}
                <Tooltip title='Descargar'>
                  <IconButton
                    size='small'
                    onClick={handleDownload}
                    color='atmosphere'
                  >
                    <CloudDownloadOutlined fontSize='small' />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Refrescar'>
                  <IconButton
                    size='small'
                    color='atmosphere'
                    onClick={() => getAllDocuments()}
                  >
                    <Refresh fontSize='small' />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Iniciar proceso'>
                  <IconButton
                    size='small'
                    color='atmosphere'
                    onClick={handleCreateDocument}
                  >
                    <DescriptionOutlined fontSize='small' />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default UserDocumentStatusToolbar;
