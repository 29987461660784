import { Chip, lighten, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";

import { theme } from "@/theme";

import DocumentListActions from "./DocumentListActions";
import {
  EDocumentPaymentStatus,
  EDocumentStatus,
} from "../UserDocumentStatus/UserDocumentStatus.types";

const chipColor = (status: keyof typeof EDocumentStatus) => {
  switch (status) {
    case "CREATED":
      return lighten(theme.palette.atmosphere?.light ?? "", 0.5);
    case "SIGNED":
      return lighten(theme.palette.success?.light ?? "", 0.5);
    case "SIGNED_PENDING":
      return lighten(theme.palette.autumn?.light ?? "", 0.5);
    default:
      return theme.palette.grey[400];
  }
};

export const colDefs: GridColDef[] = [
  {
    field: "actions",
    headerName: "Acciones",
    width: 100,
    sortable: false,
    renderCell: ({ row }) => <DocumentListActions row={row} />,
  },
  {
    field: "document_code",
    headerName: "ID",
    width: 120,
    sortable: false,
    renderCell: ({ value }) => <Typography variant='body1'>{value}</Typography>,
  },
  {
    field: "owner",
    headerName: "Propietario",
    width: 300,
    sortable: false,
    renderCell: ({ row }) => (
      <Typography variant='body1' noWrap>
        {row.owner.first_name} {row.owner.last_name}{" "}
        {row.owner.mother_last_name}
      </Typography>
    ),
  },
  {
    field: "status",
    headerName: "Estado del documento",
    width: 200,
    sortable: false,
    renderCell: ({ value }) => (
      <Chip
        label={EDocumentStatus[value as keyof typeof EDocumentStatus]}
        sx={{ background: chipColor(value) }}
      />
    ),
  },
  {
    field: "payment_status",
    headerName: "Estado del Pago",
    width: 150,
    sortable: false,
    renderCell: ({ value }) => (
      <Chip
        label={
          EDocumentPaymentStatus[value as keyof typeof EDocumentPaymentStatus]
        }
        sx={{
          background:
            value === "PAIDOUT"
              ? lighten(theme.palette.success?.light ?? "", 0.5)
              : lighten(theme.palette.autumn?.light ?? "", 0.5),
        }}
      />
    ),
  },
  {
    field: "file",
    headerName: "Nombre del Archivo",
    width: 350,
    sortable: false,
    renderCell: ({ row }) => (
      <Typography variant='body1'>{row.file.name}</Typography>
    ),
  },
  {
    field: "created_at",
    headerName: "Fecha de creación",
    width: 200,
    sortable: false,
    renderCell: ({ value }) => {
      return (
        <Typography variant='body1'>{format(value, "dd/MM/yyyy")}</Typography>
      );
    },
  },
];
