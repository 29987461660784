import { pdf } from "@react-pdf/renderer";
import { PDFDocument } from "pdf-lib";
import { useDispatch, useSelector } from "react-redux";

import { Document } from "@/components/UserDocumentStatus/UserDocumentStatus.types";
import {
  stepSelector,
  documentSelector,
  signersSelector,
  errorSignersSelector,
  documentBase64Selector,
  allDocumentsCreatedSelector,
  allContractTypes,
  statisticsSelector,
  filtersSelector,
} from "@/state/Document/selectors";
import {
  setDocumentType,
  setDocument,
  setSigners,
  removeSigner,
  updateSigner,
  setDocumentCreationStep,
  clearDocument,
  setDocumentBase64,
  setDocumentSigningPage,
  setDocumentsCreated as setDocumentsCreatedAction,
  setFilters as setFiltersAction,
} from "@/state/Document/slice";
import {
  EDocumentCreationSteps,
  EDocumentTypes,
  IDocumentSigner,
  ISliceDocumentState,
} from "@/state/Document/slice.types";

const useDocument = () => {
  const dispatch = useDispatch();
  const documentSt = useSelector(documentSelector);
  const documentStepSt = useSelector(stepSelector);
  const documentSignersSt = useSelector(signersSelector);
  const documentBase64St = useSelector(documentBase64Selector);
  const allDocumentsCreatedSt = useSelector(allDocumentsCreatedSelector);
  const allContractTypesSt = useSelector(allContractTypes);
  const documentsStatisticsSt = useSelector(statisticsSelector);
  const filtersSt = useSelector(filtersSelector);
  const hasError = useSelector(errorSignersSelector);

  const setDocumentAsBase64 = (base64: string | null) =>
    dispatch(setDocumentBase64(base64));
  const setDocumentStep = (step: EDocumentCreationSteps | null) =>
    dispatch(setDocumentCreationStep(step));
  const createNewDocumentType = (type: EDocumentTypes | null) =>
    dispatch(setDocumentType(type));
  const createNewDocument = (props: {
    documentOrderInfo: ISliceDocumentState["documentOrderInfo"];
  }) => dispatch(setDocument(props));
  const addNewSigner = (props: IDocumentSigner) => dispatch(setSigners(props));
  const removeSignerFromDocument = (uuid: string) =>
    dispatch(removeSigner(uuid));
  const setDocumentSignersPage = (page: number) =>
    dispatch(setDocumentSigningPage(page));
  const updateSignerFromDocument = ({
    prevUuid,
    data,
  }: {
    prevUuid?: string;
    data: IDocumentSigner;
  }) => dispatch(updateSigner({ prevUuid, data }));
  const clearDocumentSt = () => dispatch(clearDocument());

  const setDocumentsCreated = (props: Document[]) =>
    dispatch(setDocumentsCreatedAction(props));

  const setFilters = (filters: string) => dispatch(setFiltersAction(filters));

  const convertPDFtoBase64 = async (component: React.ReactElement) => {
    try {
      const stream = await pdf(component).toBlob();
      const reader = new FileReader();
      return new Promise<string>((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(stream);
      });
    } catch (err) {
      console.error(`convertPDFtoBase64 ${err}`);
    }
  };

  const uint8ArrayToBase64 = (buffer: Uint8Array) => {
    return btoa(
      buffer.reduce((data, byte) => data + String.fromCharCode(byte), ""),
    );
  };

  const mergePDFDocuments = async ({
    originalDocument,
    signersPage,
  }: {
    originalDocument: string;
    signersPage: string;
  }) => {
    try {
      const mergedPdf = await PDFDocument.create();

      const decodeBase64 = (data: string): Uint8Array => {
        const binaryString = atob(data.split(",")[1]);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
      };

      const originalBytes = decodeBase64(originalDocument);
      const signersBytes = decodeBase64(signersPage);

      const [originalPdf, signersPdf] = await Promise.all([
        PDFDocument.load(originalBytes, { ignoreEncryption: true }),
        PDFDocument.load(signersBytes),
      ]);

      const originalPages = await mergedPdf.copyPages(
        originalPdf,
        originalPdf.getPageIndices(),
      );

      originalPages.forEach(page => mergedPdf.addPage(page));

      const signersPages = await mergedPdf.copyPages(
        signersPdf,
        signersPdf.getPageIndices(),
      );

      signersPages.forEach(page => mergedPdf.addPage(page));

      const mergedPdfBytes = await mergedPdf.save();

      return `data:application/pdf;base64,${uint8ArrayToBase64(
        mergedPdfBytes,
      )}`;
    } catch (err) {
      console.error(`mergedPdf ${err}`);
    }
  };

  const documentWithSignersPage = async ({
    base64,
    signersComponentPage,
  }: {
    base64: string;
    signersComponentPage: React.ReactElement;
  }) => {
    try {
      const signersBase64 = await convertPDFtoBase64(signersComponentPage);

      if (signersBase64) {
        const mergedDocumentsBase64 = await mergePDFDocuments({
          originalDocument: base64,
          signersPage: signersBase64,
        });

        return mergedDocumentsBase64;
      }

      return;
    } catch (err) {
      console.error(`documentWithSignersPage ${err}`);
    }
  };

  return {
    hasError,
    documentSt,
    documentStepSt,
    documentSignersSt,
    documentBase64St,
    allDocumentsCreatedSt,
    setDocumentStep,
    createNewDocumentType,
    createNewDocument,
    addNewSigner,
    removeSignerFromDocument,
    updateSignerFromDocument,
    clearDocumentSt,
    setDocumentAsBase64,
    convertPDFtoBase64,
    mergePDFDocuments,
    documentWithSignersPage,
    setDocumentSignersPage,
    setDocumentsCreated,
    allContractTypesSt,
    documentsStatisticsSt,
    filtersSt,
    setFilters,
  };
};

export default useDocument;
