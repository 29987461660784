import { Grid, Box } from "@mui/material";
import { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";

import DocumentsOverview from "@/components/DocumentsOverview";
import { Permissions } from "@/helpers/permissions";
import { useDocument } from "@/hooks";
import AuthorizedRoute from "@/routes/AuthorizedRoute";

const DocumentsStatusDataGrid: FC = () => {
  const { allDocumentsCreatedSt, setFilters } = useDocument();

  useEffect(() => {
    return () => {
      setFilters("");
    };
  }, []);

  return (
    <AuthorizedRoute allowedPermission={Permissions.LIST_USER_DOCUMENTS}>
      <Box data-testid='documents-status-data-grid-component'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {allDocumentsCreatedSt && <DocumentsOverview />}
          </Grid>
          <Grid item xs={12}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </AuthorizedRoute>
  );
};

export default DocumentsStatusDataGrid;
