import { VisibilityOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";

// import { useMessager } from "@/hooks";

import { blueIconSx } from "./DocumentList.styles";
import { Document } from "../DocumentDetailData/DocumentDetailData.types";

interface DocumentListActionsProps {
  row: Document;
}

const DocumentListActions: FC<DocumentListActionsProps> = ({ row }) => {
  return (
    <>
      <Tooltip title='Ver Detalles'>
        <IconButton
          component={NavLink}
          to={`/dashboard/v1/admin/manage-documents/details/${row.uuid}`}
        >
          <VisibilityOutlined sx={blueIconSx} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default DocumentListActions;
